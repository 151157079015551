import { Route } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MsalGuard } from '@azure/msal-angular';

export default [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'workspaces',
    loadChildren: () => import('./workspaces/routes'),
    canActivate: [MsalGuard],
  },
  {
    path: 'members',
    loadChildren: () => import('./members/routes'),
    canActivate: [MsalGuard],
  },
] satisfies Route[];
