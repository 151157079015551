import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, filter, map, take, throwError } from 'rxjs';

import { environment } from '@env';

import { AuthApiService } from '@core/services';
import { AccessTokenResponse } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class RefreshInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<AccessTokenResponse | null>(null);

  constructor(
    public authApiService: AuthApiService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this.isRefreshRequest(request)) {
      // This interceptor only monitors 401 errors for token refreshes
      return next
        .handle(request)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401 && !this.isTokenRequest(request)) {
              return this.handle401Error(request);
            }
            return throwError(() => error);
          }));
    }

    // all other requests, just pass through
    return next.handle(request);
  }

  private isTokenRequest(request: HttpRequest<unknown>): boolean {
    return request.url === environment.authApiProtectedResource;
  }

  private isRefreshRequest(request: HttpRequest<unknown>): boolean {
    return request.url.startsWith(environment.authApiBaseUrl)
      && request.url.endsWith('token')
      && request.body instanceof FormData
      && (request.body as FormData).has('grant_type')
      && (request.body as FormData).get('grant_type') === 'refresh_token';
  }

  private handle401Error(request: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authApiService.getTokenFromCredentials$().pipe(
        map(token => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);

          return new HttpResponse({
            status: 200,
            statusText: 'OK',
            body: token,
            url: request.url
          });
        }),
        catchError((err) => {
          this.isRefreshing = false;
          return throwError(() => err);
        })
      );
    } else {
      // If a token refresh is already in progress, wait for it to complete
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        map(token => {
          return new HttpResponse({
            status: 200,
            statusText: 'OK',
            body: token,
            url: request.url
          });
        })
      );
    }
  }
}
