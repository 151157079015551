import { Component } from '@angular/core';

import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatCardImage } from '@angular/material/card';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    imports: [CommonModule, NgOptimizedImage, MatCardImage]
})
export class HomeComponent {
}
