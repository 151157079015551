<div class="home-image-panel">
  <img mat-card-image class="artemis-image" height="350" width="200" ngSrc="/assets/artemis.png" alt="Artemis" priority>
</div>
<div class="home-description-panel">
  <section>
    <h1>BSI Connect Platform (Project Artemis)</h1>
    <p><strong>The Connect Platform</strong> is the future of BSI Connect. Concepts become reality as
      legacy architecture is replaced. The modern and adaptive single page application already
      available in BSI Connect is now future-ready with inherent capability in scaleability,
      performance, rapid development and ease of maintenance.</p>
    <p>The <strong>Connect Platform Portal</strong> is provided as the administration control
      centre for the necessary control, management and insight into all deployments of the
      Connect Platform generation of applications. Deployments that benefit from the eradication of
      previous generational technical debt and performance obstacles.</p>
    <p><strong>The Connect Platform is still under active development. Whilst we try extremely hard to
      avoid it, we may make changes that will cause your work here to not to function as it once
      did. Please keep appraised of the release notes. We document the changes that are required
      to continue using your record types and documents.</strong>
    </p>
    <p>This portal will be enhanced as the BSI Connect customer base grows and real world
      improvements are delivered to our customers.</p>
  </section>
</div>
