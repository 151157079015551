import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError } from 'rxjs';

import { environment } from '@env';

import { AuthService } from '@core/services';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<string | null>(null);

  constructor(
    public authService: AuthService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.getToken();

    // If we are calling to a protected API, add the JWT to the request
    if (request.url.startsWith(environment.adminApiBaseUrl)
      || request.url.startsWith(environment.authApiBaseUrl)
      || request.url.startsWith(environment.documentApiBaseUrl)
      || request.url.startsWith(environment.storageApiBaseUrl)) {

      if (this.isTokenRequest(request)) {
        // If it's a token request, just pass through to MSAL
        return next.handle(request);
      }

      // Clone the request and add the Authorization header with the token
      let authRequest = request;
      if (token) {
        authRequest = this.addTokenHeader(request, token);
      }

      // Handle the request
      return next
        .handle(authRequest)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401 && !this.isRefreshRequest(request)) {
              return this.handle401Error(authRequest, next);
            }
            return throwError(() => error);
          }));
    }

    // all other requests, just pass through
    return next.handle(request);
  }

  private addTokenHeader(request: HttpRequest<unknown>, token: string) {
    // Clone the request and add the Authorization header with the token
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
  }

  private isTokenRequest(request: HttpRequest<unknown>): boolean {
    return request.url === environment.authApiProtectedResource;
  }

  private isRefreshRequest(request: HttpRequest<unknown>): boolean {
    return request.url.startsWith(environment.authApiBaseUrl)
      && request.url.endsWith('token')
      && request.body instanceof FormData
      && (request.body as FormData).has('grant_type')
      && (request.body as FormData).get('grant_type') === 'refresh_token';
  }

  private handle401Error(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken$().pipe(
        switchMap((newToken: string) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(newToken);

          // Retry the failed request with the new token
          return next.handle(this.addTokenHeader(request, newToken));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          return throwError(() => err);
        })
      );
    } else {
      // If a token refresh is already in progress, wait for it to complete
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap((token) => next.handle(this.addTokenHeader(request, token)))
      );
    }
  }
}
